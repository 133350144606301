<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title= "일상점검 Sheet 결과 목록"
            tableId="facilitySelfCheck"
            :merge="grid.merge"
            gridHeight="750px"
            :columns="grid.columns"
            :data="grid.data"
            :editable="!disabled"
            :columnSetting="false"
            :usePaging="false"
            @headerDataChange="headerDataChange"
          >
            <template slot="suffixTitle">
              <font v-if="!popupParam.data.lineId && !popupParam.data.checkDate" color="#C10015">
                {{$language('※ 라인과 점검년월을 선택하세요.')}}
              </font>
              <font style="font-size:0.8em;font-weight:300;">
                <q-icon name="radio_button_unchecked" class="text-black"/> :  {{$language('정상')}} &nbsp;&nbsp;
                <q-icon name="close" class="text-black"/> :  {{$language('이상')}} &nbsp;&nbsp;
                <q-icon name="circle" class="text-black"/> :  {{$language('청소')}} &nbsp;&nbsp;
                V : {{$language('교환')}} &nbsp;&nbsp;
                <q-icon name="check_box_outline_blank" class="text-black"/> : {{$language('마모')}} &nbsp;&nbsp;
                △ : {{$language('Oil 누유')}} &nbsp;&nbsp;
                ▲ : {{$language('점검필요')}} &nbsp;&nbsp;
                <q-icon name="radio_button_checked" class="text-black"/> : {{$language('비가동')}} &nbsp;&nbsp;
              </font>
            </template>
            <!-- 버튼 영역 -->
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='customCol'">
                <component
                  :is="imprComponent"
                  :col="col"
                  :props="props"
                  :inputEditable="editable&&!disabled"
                  :isImmShow="true"
                  :requestContentsCols="requestContentsCols"
                  :tableKeys="tableKeys"
                  ibmTaskTypeCd="ITT0000070"
                  ibmTaskUnderTypeCd="ITTU000123"
                  @imprChange="imprChange"
                />
              </template>
              <template v-else-if="col.name === 'cycle1'">
                <span v-if="props.row['cycle1'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'cycle2'">
                <span v-if="props.row['cycle2'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'cycle3'">
                <span v-if="props.row['cycle3'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'remark'">
                <c-text-column
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['remark']"
                  @datachange="datachange1(props)"
                />
              </template>
              <template v-else>
                <c-select
                  stype="tableselect"
                  :editable="editable"
                  :comboItems="checkboxItems"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  label=""
                  name="checkItems"
                  v-model="props.row[col.name]"
                  @datachange="datachange2(props)"
                >
                </c-select>
              </template>
            </template>
            <template slot="table-button">
              <q-btn-group outline>
                      <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="popupParam.data.dailyCheckId ? popupParam.data.dailyResultModelList : this.popupParam.data"
                  mappingType="POST"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions">></c-dialog>
    </q-form>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-daily-checking',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        col: {},
        equipmentList: [],
        data: {
          dailyCheckId: '',  // 열화점검 일련 번호
          plantCd: '',  // 사업장 코드
          checkStatusCd: '',  // 계획수립/점검중/점검완료
          lineId: '',  // 라인일련번호
          checkDeptCd: '',  // 점검부서
          checkUserId: '',  // 점검자 ID
          checkDate: '',  // 점검월
          deteriorName: '',  // 점검명
          dailyResultModelList: [], // sheet
        }
      }),
    },
    returnData: {
      type: Object,
      default: () => ({
        col1: null,
        col2: null,
        col3: null,
      }),
    }
  },
  data() {
    return {
      year: '',
      grid: {
        merge: [
          { index: 0, colName: 'equipmentCd' },
        ],
        columns: [],
        data: [],
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
      getUrl: '',
      resultItemDetail: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
      checkboxItems: [
        // { code: null, codeName: ''},
        { code: 'CHK001', codeName: 'o'},
        { code: 'CHK002', codeName: 'X'},
        { code: 'CHK003', codeName: '●'},
        { code: 'CHK004', codeName: 'V'},
        { code: 'CHK005', codeName: '□'},
        { code: 'CHK006', codeName: '△'},
        { code: 'CHK007', codeName: '▲'},
        { code: 'CHK008', codeName: '⊙'},
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.popupParam.data.checkStatusCd === 'MCSC000015' || Boolean(this.popupParam.data.sysApprovalRequestId)
    },
    // 테이블 키 멀티
    tableKeys() {
      return ['dailyCheckResultId','colkey'];
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checkItemPartName', 'checkItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/sop/mim/inspection/inpsectionTableImpr.vue'}`);
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.resultItemDetail = selectConfig.sop.min.equipment.result.daily.newItem.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.daily.newItem.url;
      // code setting
      // list setting
      this.setHeader();
    },
    setHeader() {
      if (this.popupParam.equipmentList) {
        this.$_.forEach(this.popupParam.equipmentList, _item => {
          _item.colkey = this.popupParam.col.key;
          if(_item[this.popupParam.col.key] && 
              (this.$_.findIndex(this.checkboxItems, {code: _item[this.popupParam.col.key]}) === -1)) {
            _item.remark = _item[this.popupParam.col.key]
          }
        })
        this.grid.data = this.$_.clone(this.popupParam.equipmentList)
      }
      
      let _columns = [
        {
          name: 'equipmentName',
          field: 'equipmentName',
          label: '설비명',
          align: 'center',
          style: 'width:180px',
          type: 'html',
          sortable: false,
        },
        {
          name: 'checkItemPartName',
          field: 'checkItemPartName',
          label: '부품명',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'checkItemMethod',
          field: 'checkItemMethod',
          label: '점검방법',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'checkStandard',
          field: 'checkStandard',
          label: '점검기준',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          label: '주기',
          align: 'center',
          sortable: false,
          child: [
            {
              name: 'cycle1',
              field: 'cycle1',
              label: '일',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
            {
              name: 'cycle2',
              field: 'cycle2',
              label: '주',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
            {
              name: 'cycle3',
              field: 'cycle3',
              label: '월',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
          ]
        },
      ]
      let days = [];
      days.push(
        {
          name: this.popupParam.col.key,
          field: this.popupParam.col.key,
          label: this.popupParam.col.label + this.$language('일'), // 일
          align: 'center',
          setHeader: true,
          comboItems: this.checkboxItems,
          style: 'width:200px',
          type: 'custom',
          headType: 'select',
          sortable: false,
        },
        {
          name: 'remark',
          field: 'remark',
          label: this.popupParam.col.label + this.$language('일(수치입력)'), // 일(수치입력)
          headType: 'text',
          align: 'left',
          setHeader: true,
          style: 'width:200px',
          type: 'custom',
          sortable: false,
        },
      )
      this.grid.columns = this.$_.concat(_columns, {
        name: 'year',
        field: 'year',
        label: '측정값', 
        child: days
      },
      {
          name: 'impr',
          field: 'impr',
          label: '개선진행',
          align: 'center',
          child: [
            {
              name: 'customCol',
              field: 'customCol',
              label: '개선번호/개선진행상태',
              align: 'center',
              style: 'width:350px',
              type: 'custom',
              sortable: false
            },
          ]
        },)
    },
    saveData() {
      if (this.popupParam.data.dailyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.popupParam.data.regUserId = this.$store.getters.user.userId;
              this.popupParam.data.chgUserId = this.$store.getters.user.userId;

              this.popupParam.data.utilityFlag = 'N';
              this.$_.forEach(this.grid.data, _item => {
                _item.regUserId = this.$store.getters.user.userId;
                _item.chgUserId = this.$store.getters.user.userId;
                if (!_item[this.popupParam.col.key]) {
                  this.$set(_item, this.popupParam.col.key, _item.remark)
                }
              })
              
              if (this.mappingType === 'POST') {
                this.$_.forEach(this.grid.data, _item => {
                  this.$_.forEach(this.popupParam.data.dailyResultModelList, __item => {
                    if(__item.dailyCheckResultId === _item.dailyCheckResultId) {
                      __item = _item;
                    }
                  })
                })
              } else if (this.mappingType === 'PUT') {
                this.popupParam.data.dailyResultModelList = this.$_.clone(this.grid.data);
              } 
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      
      if (this.mappingType === 'POST') {
        this.popupParam.data.dailyCheckId = result.data
        this.returnData.col1 = result.data
        this.$_.forEach(this.grid.data, _item => {
          _item.deteriorCheckId = result.data;
          _item.editFlag = 'U';
        })
      }
    },
    datachange1(props) {
      props.row[this.popupParam.col.key] = null;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    datachange2(props) {
      props.row['remark'] = null;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    headerDataChange(props, col) {
      if (col.name === this.popupParam.col.key) {
        this.$_.forEach(this.grid.data, _item => {
          _item.remark = '';
        })
      } else if (col.name === 'remark') {
        this.$_.forEach(this.grid.data, _item => {
          this.$set(_item, this.popupParam.col.key, null)
        })
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
